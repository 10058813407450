<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-28 19:46:56
 * @LastEditTime: 2021-09-29 17:56:35
 * @Description:产品流向表
 * @Param:
 * @FilePath: \JT_Web_test\src\views\statisticalAnalysis\statisticalReport\productFlowDirection.vue
 -->

<template>
    <div class="productFlowDirection">
        <div class="productFlowDirection_content reportForm_style">
            <div class="reportForm_head">
                <div class="queryCriteria">
                    <div>
                        <el-date-picker
                            v-model="date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            @change="initPage"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="company">
                    <h3>单位：万方</h3>
                </div>
            </div>
            <div class="productFlowDirection_table table-bg">
                <el-table
                    :data="tableData"
                    height="100%"
                    :cell-style="cellStyle"
                    :span-method="objectSpanMethod"
                >
                    <el-table-column
                        prop="companytype"
                        label="类别"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="tenant_name"
                        label="公司"
                    >
                    </el-table-column>
                    <el-table-column label="选择月">
                        <template v-for="(item, index) in tableHead">
                            <el-table-column
                                :key="`monthlyAccumulation_${index}`"
                                :prop="`MTD_${item.Code}`"
                                :label="item.Name"
                                width="100"
                            >
                            </el-table-column>
                        </template>
                        <el-table-column
                            key="MTD_total"
                            prop="MTD_sumtotal"
                            label="合计"
                            width="120"
                            style="background:#F5F2ED; color:#823802;"
                        >
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="年累计">
                        <template v-for="(item, index) in tableHead">
                            <el-table-column
                                :key="`monthlyAccumulation_${index}`"
                                :prop="`YTD_${item.Code}`"
                                :label="item.Name"
                                width="100"
                            >
                            </el-table-column>
                        </template>
                        <el-table-column
                            key="YTD_total"
                            prop="sumtotal"
                            label="合计"
                            width="120"
                        >
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="productFlowDirection_chart">
                <div class="projectType">
                    <h4>工程类型</h4>
                    <div class="chart" id="projectTypeChart"></div>
                </div>
                <div class="statistics">
                    <h4>统计柱图</h4>
                    <div class="chart" id="statisticsChart"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/utils/util';

export default {
    name: 'product-flow-direction',
    components: {},
    props: {},
    data() {
        return {
            date: '',
            tableHead: [
                {
                    Name: '电力',
                    Code: '103-DL0',
                },
                {
                    Name: '房建',
                    Code: '103-FJ0',
                },
                {
                    Name: '轨道',
                    Code: '103-GD0',
                },
                {
                    Name: '管廊',
                    Code: '103-GL1',
                },
                {
                    Name: '公路',
                    Code: '103-GL2',
                },
                {
                    Name: '海洋',
                    Code: '103-HY0',
                },
                {
                    Name: '其他',
                    Code: '103-QT0',
                },
                {
                    Name: '隧道',
                    Code: '103-SD0',
                },
                {
                    Name: '水利',
                    Code: '103-SL0',
                },
                {
                    Name: '市政',
                    Code: '103-SZ0',
                },
                {
                    Name: '铁路',
                    Code: '103-TL0',
                },
                {
                    Name: '厂房',
                    Code: '103-CF0',
                },
            ],
            tableData: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.date = utils.currentTimeFormat('yyyy-MM-dd');
        this.initPage();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async initPage() {
            const monthlyAccumulation = await this.gitTableData(1);
            const annualAccumulation = await this.gitTableData(2);
            this.dataProcessing(monthlyAccumulation?.mpflist, annualAccumulation?.mpflist);
        },
        // 数据处理
        dataProcessing(monthlyData, annualData) {
            const returnData = [];
            annualData.forEach(item => {
                const row = monthlyData.filter(k => k.tenant_id === item.tenant_id);
                if (row.length > 0) {
                    item.monthlyData = row[0].typelist;
                    item.MTD_sumtotal = row[0].sumtotal;
                } else {
                    item.monthlyData = [];
                    item.MTD_sumtotal = 0;
                }
                this.tableHead.forEach(k => {
                    const MTD = item.monthlyData.filter(z => z.engineering_type === k.Code);
                    if (MTD.length > 0) {
                        item[`MTD_${k.Code}`] = MTD[0].deliver_volume;
                    } else {
                        item[`MTD_${k.Code}`] = 0;
                    }
                });
                this.tableHead.forEach(j => {
                    const YTD = item.typelist.filter(k => k.engineering_type === j.Code);
                    if (YTD.length > 0) {
                        item[`YTD_${j.Code}`] = YTD[0].deliver_volume;
                    } else {
                        item[`YTD_${j.Code}`] = 0;
                    }
                });
            });
            const result = annualData.reduce((a, b) => {
                if (a[b.companytype]) {
                    a[b.companytype].push(b);
                } else {
                    a[b.companytype] = [b];
                }
                return a;
            }, {});
            for (const k in result) {
                returnData.push(...result[k]);
            }
            this.tableData = returnData;

            this.chartDataProcessing(monthlyData);
        },

        flitterData(arr) {
            const spanOneArr = [];
            let concatOne = 0;
            arr.forEach((item, index) => {
                if (index === 0) {
                    spanOneArr.push(1);
                } else if (item.companytype === arr[index - 1].companytype) {
                    spanOneArr[concatOne] += 1;
                    spanOneArr.push(0);
                } else {
                    spanOneArr.push(1);
                    concatOne = index;
                }
            });
            return {
                one: spanOneArr,
            };
        },
        // 合并列
        // eslint-disable-next-line consistent-return
        objectSpanMethod({ rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.flitterData(this.tableData).one[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col,
                };
            }
        },

        // 合计列样式
        cellStyle({ column }) {
            let cellStyle = '';
            if (['MTD_sumtotal', 'sumtotal'].indexOf(column.property) > -1) {
                cellStyle = 'background: #F5F2ED; color: #823802; border-color: #D6CEC9;';
            }
            return cellStyle;
        },

        // 饼图数据处理
        chartDataProcessing() {

        },

        // 获取列表数据
        gitTableData(type) {
            const Url = `/interfaceApi/datacenter/thematic/sales/getmonth_product_flow/total?date=${this.date}&type=${type}`;
            return this.$axios.$get(Url, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
@import './CSS/index.stylus';
.productFlowDirection
    height 100%
    padding 0.2rem
    .productFlowDirection_content
        height 100%
        background #fff
        padding 0.14rem
        .productFlowDirection_table,
        .productFlowDirection_chart
            height calc((100% - 0.64rem)/2)
        .productFlowDirection_table
            margin-bottom 0.14rem
            >>>.el-table
                th
                    border-bottom 1px solid rgba(215,215,215,0.7)
                thead
                    tr
                        &:nth-of-type(1)
                            th
                                background #EDF0F5
                        &:nth-of-type(2)
                            th
                                background #F6F8FC
        .productFlowDirection_chart
            display flex
            justify-content space-between
            >div
                width calc((100% - 0.14rem)/2)
                padding 0.2rem
                border 1px solid #D7D7D7
                h4
                    font-size 0.18rem
                    line-height 0.22rem
                    padding-left 0.1rem
                    border-left 6px solid #4D7DE0
                .chart
                    height calc(100% - 0.22rem)
</style>